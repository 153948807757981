body {
  direction: ltr;
  box-sizing: border-box !important;
  font-family: " Gothic", "Arial Narrow", Arial, sans-serif !important;
  background-color: #e8e5e2 !important;
  font-family: "Gothic" !important;
}
p {
  margin: 0 !important;
  padding: 0 !important;
}
* {
  padding: 0;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.iconsStyle {
  width: 35px;
  height: 35px;
  padding: 10px;
  background-color: #e8e5e2;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Collapsible__contentOuter {
  background: #e8e5e2 !important;
  padding: 0 !important;
  border: 1px solid #a4a4a4;
}
.Collapsible__contentInner {
  background: #e8e5e2 !important;
  padding: 20px;
}
.Collapsible__trigger,
.is-open {
  font-size: 24px !important;
  font-family: "snellRoundhand" !important;
  font-weight: normal !important;
}
.Collapsible__contentInner {
  font-family: "Gothic" !important;
}
.snell {
  font-family: SnellRoundhand;
}
.flexCol {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly;
}
.Carousel-indicators-2 {
  position: absolute !important;
  bottom: 10px !important;
  box-sizing: border-box;
}
@media (min-width: 750px) {
  .css-1amk2e9-MuiToolbar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
ul {
  list-style: none !important;
  text-align: left !important;
  color: #e8e5e2;
  padding: 0 !important;
}
.margin1 {
  margin-top: -60px;
}
li {
  margin-bottom: 10px !important;
}
.omynav {
  background-color: rgba(47, 61, 76, 0.4) !important;
  transition: background-color 0.3s ease-in-out !important;
  box-shadow: 0 0 0 !important;
}
.newSettle {
  box-shadow: 10px 10px 10px #8d8888;
}
.new-settle{
  border-radius: 0px 10px 10px 0px;
}
.our-Newsletter{
  border-radius: 10px 0px 0px 10px;
}
@media only screen and (max-width: 665px) {
  .new-settle{
    border-radius: 10px;
  }
  .our-Newsletter{
    border-radius: 10px ;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 170px 170px 170px;
  gap: 15px;
  padding: 10px;
}
.imgWidth {
  transition: width 0.3s ease-in-out;
}
.mynav {
  background-color: #2f3d4c !important;
  position: absolute !important;
}
.cabinsSuits {
  width: 180px !important;
}
.anime {
  transition: all 0.5s;
  border-radius: 20px !important;
}
.anime:hover {
  transform: scale(1.08);
  filter: brightness(90%);
  cursor: pointer;
}
.w100 {
  object-fit: cover !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(47, 61, 76, 0.4);
  color: #e8e5e2 !important;
  width: fit-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: -823737373737;
}
.dropdown-content a {
  color: #e8e5e2 !important;
}
.backGroundCont {
  background-color: rgba(47, 61, 76, 1) !important;
}
.centerText {
  text-align: center;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ddd;
  color: black !important;
  z-index: 823 !important;
}
.dropdown:hover .dropdown-content {
  display: block;
  z-index: 823 !important;
}
.MuiDrawer-paperAnchorRight,
.MuiDrawer-paperAnchorLeft {
  position: fixed !important;
  background-color: #001738 !important;
  color: #ffffff !important;
  width: 302px !important;
}
@media (max-width: 750px) {
  .flex1 div img,
  .conSlide {
    width: 350px !important;
  }
  .conSlide {
    margin: 0 !important;
  }
  .mobLogo1 {
    width: 80px !important;
  }
  .moblogo2 {
    width: 100px !important;
  }
  .emailNew {
    width: 180px !important;
  }
  .subscribe {
    flex-wrap: wrap !important;
    text-align: center;
  }
  .title {
    width: fit-content !important;
  }
  .cabinsB {
    margin-bottom: 10px !important;
  }
}
.pr {
  padding-right: 0 !important;
}
@media (max-width: 850px) {
  .title {
    width: fit-content !important;
  }
  .inharm {
    font-size: 25px !important;
    left: 10% !important;
  }
  .pagecar {
    height: 40vh !important;
  }
  .marginE {
    margin: 30px 0px 40px 0px !important;
  }
}
.MuiPaper-elevation16 {
  background-color: #2f3d4c !important;
}

.index0 {
  grid-row: 1 / 4;
  grid-column: 1 / 2;
}
.index8 {
  grid-row: 1 / 4;
  grid-column: 5 / 6;
}
.index3 {
  grid-row: 1 / 3;
  grid-column: 3 / 4;
}
.index2 {
  grid-row: 2 / 4;
  grid-column: 2 / 3;
}
@media (max-width: 1099px) {
  .nc {
    text-align: left !important;
    justify-content: flex-start !important;
  }
  .footer {
    padding: 10px;
  }
  .kalam {
    min-height: 100px !important;
    padding: 10px !important;
  }
  .index0 {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  .index8 {
    grid-row: 1 / 2;
    grid-column: 5 / 6;
  }
  .index3 {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
  }
  .index2 {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
}

.br {
  display: none;
}
@media (max-width: 750px) {
  .heightSora {
    height: 60vh !important;
    object-fit: cover;
  }
  .br {
    display: block;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: 15px;
    padding: 10px;
  }
  .soraMed {
    height: 400px !important;
  }
  .index0 {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  .index8 {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
  .index3 {
    grid-row: 3 / 4;
    grid-column: 1/ 2;
  }
  .index2 {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
  }
}
@media (min-width: 800px) {
  .flexCol1 {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;
  }
}
@media (max-width: 1000px) {
}
@media (max-width: 700px) {
  .MuiDrawer-paperAnchorRight {
    width: 300px !important;
    box-sizing: border-box !important;
  }

  .Nile {
    width: 100% !important;
    line-height: 1.2;
    font-size: 36px !important;
  }
  .Nile1 {
    font-size: 36px !important;
  }
  .Luxury {
    font-size: 36px !important;
  }
  .dining {
    font-size: 36px !important;
  }
  .footer {
    padding: 20px !important;
    justify-content: flex-start !important;
  }
  .Luxury {
    font-family: "SnellRoundhand1";
    width: 100px;
    color: #2f3d4c !important;
    line-height: 1;
  }
}
.mui-fixed {
  position: static !important;
}
.flex {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
.footerbacktop {
  background-color: #28374e;
  height: 25px;
  margin-top: 20px;
}

a {
  text-decoration: none !important;
  color: #e8e5e2;
}
.footer {
  width: 100%;
  font-size: 18px !important;
  background-color: #2f3d4c;
  box-sizing: border-box !important;
  position: relative;
  box-sizing: border-box !important;
  min-height: 25vh !important;
  align-items: center;
  margin-top: 0 !important;
  padding: 25px !important;
}

@media (max-width: 745px) {
}
.butbook {
  border: 1px solid #e8e5e2 !important;
  border-radius: 10px !important;
}
.inharm {
  font-size: 16px;
}
.butbook:hover {
  background-color: #e8e5e2 !important;
  color: black;
}
.footerbackbottom {
  background-image: url("/public/BackWater.png");
  background-color: #28374e;
  height: 70px;
}
.Nile {
  font-size: 60px;
  width: 300px;
  line-height: 1.2;
}
.fonty {
  color: #28374e !important;
  font-family: "SnellRoundhand" !important;
}
.MuiPaper-rounded li {
  margin: 0 !important;
}
.Luxury {
  font-family: "SnellRoundhand1";
  font-size: 49px;
  width: 100%;
  line-height: 1;
  font-weight: 700;
}
.cabins {
  font-size: 35px;
  font-weight: 700;
  font-style: italic;
}
.Nile1 {
  font-size: 40px;
}
.dining {
  font-size: 42px;
}
.Imagine {
  max-width: 100%;
  color: #2f3d4c;
  font-size: 18px;
}
.Cabins {
  font-size: 22px;
  text-align: center;
}
.Availability button {
  color: #e8e5e2 !important;
  background-color: #2f3d4c !important;
  font-size: 17px !important;
  padding: 3px 23px !important;
  border-radius: 10px !important;
}
.line {
  margin: 0px 19px;
  height: 3px !important;
  background-color: #2f3d4c !important;
}
.slick-slider {
  height: 100% !important;
}
.field {
  width: 100%;
  background: #e8e5e2 !important;
  border-radius: 5px;
  padding-right: 0 !important;
}
.customTextField:focus {
  outline: none !important; /* Remove the default focus outline */
  box-shadow: 0 0 0 2px red !important; /* Set a custom box shadow for the focus state */
}
.ps-sidebar-container {
  background: transparent !important;
  border-right: none !important;
}
.ps-submenu-content {
  background-color: transparent !important;
}
.ps-sidebar-root {
  border-right: none !important;
  color: #e8e5e2 !important;
}
.ps-sidebar-root li {
  color: #e8e5e2 !important;
}
.ps-sidebar-root li :hover {
  color: #2f3d4c !important;
}
.field1 {
  width: 100%;
  background-color: #e8e5e2;
  border-radius: 5px;
  padding-right: 0 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2f3d4c !important;
}
.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  border-color: red !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #2f3d4c !important;
}
.contactInp {
  background-color: transparent;
  outline: none;
  border: 2px solid #2f3d4c;
  width: 100%;
  height: 80.13px;
  border-radius: 20px;
  padding: 5px;
}
.contactInp::placeholder {
  opacity: 0.51; /* Firefox */
  font-size: 25px;
  color: #2f3d4c;
}
textarea {
  padding: 0 !important;
}

/* .slick-prev {
  left: 10px !important;
  right: auto !important;
}

.slick-next {
  right: 10px !important;
  left: auto !important;
} */
.slick-arrow::before {
  font-size: 30px !important;
}
.slideBut button {
  background: transparent;
  border-radius: 50%;
  padding: 2px 5px;
  background: #e8e5e2;
  opacity: 0.75;
}
.slideBut button svg polyline {
  stroke: #2f3d4c;
}
.flex1 {
  flex-wrap: wrap;
}
.GallerySlider .slick-slide > div {
  display: grid !important;
  place-items: center !important;
  width: 60%;
  margin-top: 50px;
  margin: auto;
  height: 550px !important;
  padding: 0px;
}
.HomeSlider .slick-arrow::before {
  font-size: 40px !important;
}

.SmallSlider .slick-prev {
  display: none !important;
}
.SmallSlider div {
  height: 100% !important;
}
.SmallSlider .slick-dots {
  z-index: 9;
  bottom: 20px !important;
}
.slick-dots li button::before {
  color: #2f3d4c !important;
  opacity: 1 !important;
}
.slick-dots .slick-active button::before {
  color: #b4c2cd !important;
}
.SmallSlider .slick-next {
  display: none !important;
}
.slick-dots {
  bottom: 6px !important;
}
.slick-dots button::before {
  font-size: 15px !important;
}
.HomeSlider .slick-prev {
  left: -2px !important;
  z-index: 4;
  top: 40% !important;
}
.HomeSlider .slick-next {
  right: 17px !important;
  top: 40% !important;

  z-index: 4;
}
.HomeSlider .slick-slide > div {
  display: grid !important;
  place-items: center !important;
}
.HomeSlider > div {
  display: grid !important;
  place-items: center !important;

  width: 100%;
  min-height: 600px !important;
  padding: 0px;
}
.css-sox5kk-MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.slick-next {
  right: -40px !important;
}
.slick-prev {
  left: -50px !important;
}
.slick-dots {
  display: flex !important;
  justify-content: center !important;
}
.Collapsible {
  background-color: #2f3d4c;
  border-radius: 6px;
  color: #e8e5e2 !important;
  margin-bottom: 20px;
}
.Collapsible > div {
  display: block;
}
.Collapsible__contentOuter {
  background-color: white !important;
  color: #2f3d4c;
  padding: 0 10px;
}
.local-info {
  color: #2f3d4c;
}
.collapsible-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.collapsible-trigger:after {
  content: "\25B6"; /* Right arrow */
  font-size: 12px;
  margin-left: 8px;
  transition: transform 0.2s ease-in-out;
}

.collapsible-trigger.collapsible-open:after {
  content: "\25BC"; /* Down arrow */
}
.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -24px 0 #2f3d4c, 24px 0 #fff;
  }
  66% {
    background: #2f3d4c;
    box-shadow: -24px 0 #fff, 24px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -24px 0 #fff, 24px 0 #2f3d4c;
  }
}
.text-box * {
  color: #28374e !important;
}
.wellnes * {
  color: #e8e5e2 !important;
}
.Nile .habd {
  color: #2f3d4c !important;
  font-weight: 200 !important;
}
.Nile1 .habd * {
  color: #2f3d4c !important;
}
.Nile2 .habd * {
  color: #2f3d4c !important;
}
.Nile .habd * {
  color: #2f3d4c !important;
}
.Nile .habd ul li {
  font-family: "snellRoundhand1";
  margin: 0 !important;
  font-size: 40px !important;
  margin-left: 7px !important;
  margin-bottom: 0 !important;
  font-weight: 600;
}
.Nile .habd p {
  margin: 0 !important;
  font-size: 45px !important;
  margin-bottom: 10px !important;
}

.Nile1 .habd ul li {
  font-family: "snellRoundhand1";
  margin: 0 !important;
  font-size: 30px !important;
  margin-left: 7px !important;
  font-style: normal !important;
  font-weight: 600;
}
.Nile2 .habd p{
 
  margin: 0 !important;
  font-size: 35px !important;
  font-style: normal !important;
  font-weight: 500;
}
.Nile2 .habd ul li {
  font-family: "snellRoundhand1";
  margin: 0 !important;
  font-size: 40px !important;
  margin-left: 7px !important;
  font-style: normal !important;
  font-weight: 600;
}
.cabins {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.coler * {
  color: #2f3d4c !important;
  text-align: center !important;
}
.habd ul {
  margin-bottom: 0 !important;
}
.title33 {
  margin-left: 15vw !important;
}

.spinner {
  -webkit-animation: spin 1.4s infinite linear;
  animation: spin 1.4s infinite linear;
}

.home-container {
  padding: 20px 50px !important;
}

.home-container * {
  font-style: normal !important;
}
@media (max-width: 1000px) {
  .home-container {
    padding: 20px 20px !important;
  }
}
.imageContact{
  width: 100%;
  height: 100px;
  cursor: pointer;
  margin-top: 55px;
  border-radius: 10px;
}


.text-image-contact {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  text-decoration: underline;
  cursor: pointer;
}
.contact-container{
  position:relative;
  align-items: center;
  
}
.contact-container:hover{
  transform: scale(1.09);
  transition: .5s;
 }
.contact-container:hover .text-image-contact{
  display: flex;
  transition: .5s;
 }
