@font-face {
  font-family: "SnellRoundhand";
  src: url("./fonts/SnellRoundhand-BlackScript.ttf") format("truetype");
}
@font-face {
  font-family: "SnellRoundhand1";
  src: url("./fonts/SnellBT-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gothic";
  src: url("./fonts/CenturyGothic.ttf") format("truetype");
}

.local-info {
  font-family: SnellRoundhand;
  text-align: center;
  font-style: italic;
}
